/* eslint-disable import/no-anonymous-default-export */

const theme = {
    space: [0, 4, 8, 16, 32, 64, 128, 256, 356, 512],
    fontSizes: [12, 14, 16, 20, 24, 32, 64, 84, 128, 248, 500],
    lineHeights: [0, 0.5, 1, 1.5, 2, 2.5],
    colors: {
        blackened: '#050505',
        dark: '#202020',
        darkened: '#3d3d3d',
        darkish: '#4d4d4d',
        darkling: '#575757',
        dimmed: '#939393',
        bright: '#f7f7f7',
        book: '#fff9f9',
        shine: 'white'
    },
    header: {

    },
    footer: {

    },
    fonts: {
        primary: "roboto",
        text: "mali",
        logo: "roboto"
    }
}

theme.buttons = {

}

theme.textStyles = {
    primary: {
        fontSize: '40px'
    }
}

theme.colorStyles = {
    primary: {
        color: 'red'
    }
}

Object.defineProperty(exports, "__esModule", {
    value: true
})

exports.default = theme