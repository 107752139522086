import styled from '@emotion/styled'
import { typography, size, space, color, width, height, minWidth, maxWidth, textShadow, display, opacity } from 'styled-system'
import { textStyle, colorStyle } from "@styled-system/variant"
import { variant } from '@styled-system/variant'

const Text = styled.span`
    line-height: 1.5;

    ${textStyle};
    ${colorStyle};
    ${typography};
    ${space};
    ${display};
    ${opacity};
    ${size};
    ${textShadow};
    ${color};
    ${width};
    ${height};
    ${minWidth};
    ${maxWidth};

    ${({textDecoration}) => textDecoration ? `text-decoration: ${textDecoration};` : ''}

    ${variant({
        variants: {
            light: {
                color: 'bright'
            },
            dark: {
                color: 'dark'
            }
        }
    })}
`

Text.propTypes = {
    ...typography.propTypes,
    ...space.propTypes,
    ...display.propTypes,
    ...opacity.propTypes,
    ...size.propTypes,
    ...color.propTypes,
    ...width.propTypes,
    ...height.propTypes,
    ...maxWidth.propTypes,
    ...minWidth.propTypes,
    ...textShadow.propTypes
}

export default Text