import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import useSiteMetadata from "../hooks/useSiteMetadata"

const buildMeta = ({pageTitle, pageDescription, ogImage, siteMetadata}) => (
    [
        { name: 'description', content: pageDescription },
        { property: 'og:title', content: pageTitle, },
        { property: 'og:image', content: ogImage || '' },
        { property: 'og:description', content: pageDescription },
        { property: 'og:type', content: 'website' }
      ]
)

const SEO = ({ isRootPath = false, schema, description, lang, title }) => {
    const siteMetadata = useSiteMetadata()
    const { defaultOgImage } = useStaticQuery(
        graphql`
            query SeoQuery {
                defaultOgImage: file(absolutePath: { regex: "/default-og-image.jpg/" }) {
                    childImageSharp {
                        fixed(width: 1200, height: 630, quality: 95) {
                            src
                            width
                            height
                        }
                    }
                }
            }
        `
    )

  const pageTitle = isRootPath ? `${siteMetadata.title}` : `${title} | ${siteMetadata.title}`
  const pageDescription = isRootPath ? siteMetadata.description : (description || siteMetadata.description)
  const ogImage = schema && schema.primaryImageOfPage ?
    schema.primaryImageOfPage : defaultOgImage?.childImageSharp?.fixed?.src

  const builtMeta = buildMeta({pageTitle, pageDescription, siteMetadata, ogImage})

  const { type, ...schemaProps } = schema

  return (
    <Helmet
        htmlAttributes={{ lang }}
        title={pageTitle}
        meta={builtMeta}
    >
        {type && (
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": type,
                    ...schemaProps
                })}
            </script>
        )}
    </Helmet>
  )
}

export default SEO
