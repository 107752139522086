import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

const GlobalStyle = createGlobalStyle`
    ${normalize}

    html, body {
        display: flex;
        flex: 1;
        height: 100%;
        width: 100%;
    }

    body {
        padding: 0;
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #___gatsby {
        width: 100%;
        flex: 1;
    }

    #gatsby-focus-wrapper {
        flex: 1;
        height: 100%;
    }

    .fh {
        flex: 1;
        height: 100%;
    }

    #root {
        width: 100%;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
    }

    * {
        box-sizing: border-box;
    }
)`

export default GlobalStyle